
import React, { useState } from 'react';
import styles from "./register.module.css";
import { Button, TextField, IconButton, InputAdornment, Snackbar, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = ({ setAlert }) => {
  const [businessFormData, setBusinessFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [activeForm,setActiveForm]=useState("");
  const [wellBeingFormData, setWellBeingFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorsBusiness, setErrorsBusiness] = useState({});
  const [errorsWellBeing, setErrorsWellBeing] = useState({});
  const navigation = useNavigate();

  const validateForm = (formData) => {
    let formErrors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.email || !emailRegex.test(formData.email)) formErrors.email = "Invalid email format";
    if (!formData.password) formErrors.password = "Password is required";
    if (!formData.confirmPassword) formErrors.confirmPassword = "Please confirm your password";
    if (formData.password !== formData.confirmPassword) formErrors.passwordMatch = "Passwords don't match";
    if (formData.password && !passwordRegex.test(formData.password)) {
      formErrors.password = "Password must be at least 8 characters, including an uppercase letter, a number, and a special character.";
    }
    return formErrors;
  };
const disableFields = (formData) => {
  const hasData = Object.values(formData).some((value) => value !== "");
  return hasData;
};

  const handleSubmit = async (e, formType) => {
    e.preventDefault();
    const formData = formType === 'business' ? businessFormData : wellBeingFormData;
    const serviceType = formType === 'business' ? 'Businesses' : 'Well-being';

    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      if (formType === 'business') {
        setErrorsBusiness(validationErrors);
      } else {
        setErrorsWellBeing(validationErrors);
      }
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/signup`, {
        username: formData.name,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        serviceType,
      });

      setAlert({
        open: true,
        message: response.data.message,
        severity: "success",
      });
      setTimeout(() => {
        navigation("/login");
      }, 1000);

      if (formType === 'business') {
        setBusinessFormData({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      } else {
        setWellBeingFormData({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      }
    } catch (error) {
      setAlert({
        open: true,
        message: error.response ? error.response.data.message : error.message,
        severity: "error",
      });
    }
  };

  const handleChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'business') {
      if (disableFields({ ...businessFormData, [name]: value })){
        setActiveForm("business");
      }else{
        setActiveForm("");
      }
        setBusinessFormData({ ...businessFormData, [name]: value });
    } else {
       if (disableFields({ ...wellBeingFormData, [name]: value })) {
         setActiveForm("wellBeing");
       }else{
        setActiveForm("");
       }
      setWellBeingFormData({ ...wellBeingFormData, [name]: value });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const renderForm = (formType) => {
    const formData = formType === 'business' ? businessFormData : wellBeingFormData;
    const formErrors = formType === 'business' ? errorsBusiness : errorsWellBeing;

    return (
      <form onSubmit={(e) => handleSubmit(e, formType)}>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={(e) => handleChange(e, formType)}
          margin="normal"
          required
          error={!!formErrors.name}
          helperText={formErrors.name}
          disabled={
            !activeForm ? false : activeForm === formType ? false : true
          }
          sx={{
            fontFamily: "Helvetica Now Display",
            "& .MuiInputBase-input": { fontFamily: "Helvetica Now Display" },
            "& .MuiInputLabel-root": {
              fontSize: "0.8rem",
              fontFamily: "Helvetica Now Display",
            },
          }}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          name="email"
          type="email"
          value={formData.email}
          onChange={(e) => handleChange(e, formType)}
          margin="normal"
          required
          error={!!formErrors.email}
          helperText={formErrors.email}
          disabled={
            !activeForm ? false : activeForm === formType ? false : true
          }
          sx={{
            fontFamily: "Helvetica Now Display",
            "& .MuiInputBase-input": { fontFamily: "Helvetica Now Display" },
            "& .MuiInputLabel-root": {
              fontSize: "0.8rem",
              fontFamily: "Helvetica Now Display",
            },
          }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          name="password"
          type={showPassword ? "text" : "password"}
          value={formData.password}
          onChange={(e) => handleChange(e, formType)}
          margin="normal"
          required
          error={!!formErrors.password}
          helperText={formErrors.password}
          disabled={
            !activeForm ? false : activeForm === formType ? false : true
          }
          sx={{
            fontFamily: "Helvetica Now Display",
            "& .MuiInputBase-input": { fontFamily: "Helvetica Now Display" },
            "& .MuiInputLabel-root": {
              fontSize: "0.8rem",
              fontFamily: "Helvetica Now Display",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? (
                    <VisibilityOff sx={{ fontSize: "2rem" }} />
                  ) : (
                    <Visibility sx={{ fontSize: "2rem" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Confirm Password"
          variant="outlined"
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          value={formData.confirmPassword}
          onChange={(e) => handleChange(e, formType)}
          margin="normal"
          required
          error={!!formErrors.confirmPassword || !!formErrors.passwordMatch}
          helperText={formErrors.confirmPassword || formErrors.passwordMatch}
          disabled={
            !activeForm ? false : activeForm === formType ? false : true
          }
          sx={{
            fontFamily: "Helvetica Now Display",
            "& .MuiInputBase-input": { fontFamily: "Helvetica Now Display" },
            "& .MuiInputLabel-root": {
              fontSize: "0.8rem",
              fontFamily: "Helvetica Now Display",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                  {showConfirmPassword ? (
                    <VisibilityOff sx={{ fontSize: "2rem" }} />
                  ) : (
                    <Visibility sx={{ fontSize: "2rem" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          sx={{
            backgroundColor: "rgb(0, 13, 16)",
            mt: "1.5rem",
            fontFamily: "Helvetica Now Display",
          }}
          className="customBtn"
          type="submit"
          variant="contained"
          disabled={
            !activeForm ? false : activeForm === formType ? false : true
          }
        >
          Register
        </Button>
      </form>
    );
  };

  return (
    <div className={styles.registerContainer}>
      <h2 className={styles.header}>Register</h2>
      <div className={styles.registerFormContainer}>
        <div className={styles.businessesCont}>
          <h3>Businesses</h3>
          {renderForm('business')}
        </div>
        <div className={styles.wellBeingCont}>
          <h3>Well-being services</h3>
          {renderForm('wellBeing')}
        </div>
      </div>
      <div onClick={() => { navigation("/login") }} className={styles.confirm}>
        <span>Already have an account? </span>
        <span>Sign in</span>
      </div>
    </div>
  );
};

export default Register;




import React, { useEffect, useState } from "react";
import styles from "./contact.module.css";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { scrollToTop } from "../../utilities/scrollToTop";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    title: "",
    company: "",
    serviceType: "Seeking well-being",
    location: "",
    email: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [location]);

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.title) newErrors.title = "Title/Designation is required.";
    if (!formData.company) newErrors.company = "Company is required.";
    if (!formData.location) newErrors.location = "Location is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid email is required.";
    if (!formData.phone || !/^\d{10}$/.test(formData.phone))
      newErrors.phone = "Valid 10-digit phone number is required.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      const data = {
        service_id: "service_dav7a2y",
        template_id: "template_ihlsvxh",
        user_id: "_PzVYsRg50AH9bal5",
        template_params: {
          name: formData.name,
          title: formData.title,
          company: formData.company,
          serviceType: formData.serviceType,
          location: formData.location,
          email: formData.email,
          phone: formData.phone,
          reply_to:formData.email
        },
      };

      try {
        const response = await fetch(
          "https://api.emailjs.com/api/v1.0/email/send",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          setFormData({
            name: "",
            title: "",
            company: "",
            serviceType: "Seeking well-being",
            location: "",
            email: "",
            phone: "",
          });
          setAlert({
            open: true,
            message: "Your message has been sent!",
            severity: "success",
          });
        } else {
          const error = await response.json();

          setFormData({
            name: "",
            title: "",
            company: "",
            serviceType: "Seeking well-being",
            location: "",
            email: "",
            phone: "",
          });
          setAlert({
            open: true,
            message: "Error sending message: " + JSON.stringify(error),
            severity: "error",
          });
        }
      } catch (error) {
        setFormData({
          name: "",
          title: "",
          company: "",
          serviceType: "Seeking well-being",
          location: "",
          email: "",
          phone: "",
        });
        setAlert({
          open: true,
          message: "An error occurred: " + error.message,
          severity: "error",
        });
      }

    }
  };

  const handleChange = (e) => {
    // const validationErrors = validate();
    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    // } else {
    //   setErrors({});
    // }
    // setErrors({});
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleServiceTypeChange = (event, newServiceType) => {
    if (newServiceType) {
      setFormData((prev) => ({ ...prev, serviceType: newServiceType }));
    }
  };

  return (
    <div>
      <div className={`${styles.authContainer}  `}>
        <div className={styles.registerContainer}>

          <div className={styles.formContainer}>
            <div className={styles.formContainerSec}>
              <div className={styles.subHeaderCont}>
                <h3 className={styles.subHeader}>
                  OuiMoose is looking forward to serving your well-being needs
                </h3>
              </div>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Your Name"
                  variant="outlined"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  margin="normal"
                  sx={{
                    fontFamily: "Helvetica Now Display",
                    "& .MuiInputBase-input": {
                      fontFamily: "Helvetica Now Display",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.8rem",
                      fontFamily: "Helvetica Now Display",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Title/Designation"
                  variant="outlined"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  error={!!errors.title}
                  helperText={errors.title}
                  margin="normal"
                  sx={{
                    fontFamily: "Helvetica Now Display",
                    "& .MuiInputBase-input": {
                      fontFamily: "Helvetica Now Display",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.8rem",
                      fontFamily: "Helvetica Now Display",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Company"
                  variant="outlined"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  error={!!errors.company}
                  helperText={errors.company}
                  margin="normal"
                  sx={{
                    fontFamily: "Helvetica Now Display",
                    "& .MuiInputBase-input": {
                      fontFamily: "Helvetica Now Display",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.8rem",
                      fontFamily: "Helvetica Now Display",
                    },
                  }}
                />
                <ToggleButtonGroup
                  fullWidth
                  value={formData.serviceType}
                  exclusive
                  onChange={handleServiceTypeChange}
                  sx={{ my: 1 }}
                >
                  <ToggleButton
                    value="Seeking well-being"
                    sx={{
                      mt: 1,
                      backgroundColor: "", 
                      color: "rgb(0, 13, 16)",
                      outlineColor: "rgb(0, 13, 16)",
                      borderColor: "rgb(0, 13, 16)",
                      fontWeight: "700", 
                      "&.Mui-selected": {
                        backgroundColor: "rgb(0, 13, 16)",
                        color: "white", 
                        "&:hover": {
                          backgroundColor: "rgb(0, 13, 16)",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "", 
                      },
                    }}
                  >
                    Seeking well-being
                  </ToggleButton>
                  <ToggleButton
                    value="Offering well-being"
                    sx={{
                      mt: 1,
                      backgroundColor: "", 
                      color: "rgb(0, 13, 16)",
                      outlineColor: "rgb(0, 13, 16)",
                      borderColor: "rgb(0, 13, 16)",
                      fontWeight: "700", 
                      "&.Mui-selected": {
                        backgroundColor: "rgb(0, 13, 16)",
                        color: "white", 
                        "&:hover": {
                          backgroundColor: "rgb(0, 13, 16)", 
                        },
                      },
                      "&:hover": {
                        backgroundColor: "",
                      },
                    }}
                  >
                    Offering well-being
                  </ToggleButton>
                </ToggleButtonGroup>

                <TextField
                  fullWidth
                  label="Location"
                  variant="outlined"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location}
                  margin="normal"
                  sx={{
                    fontFamily: "Helvetica Now Display",
                    "& .MuiInputBase-input": {
                      fontFamily: "Helvetica Now Display",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.8rem",
                      fontFamily: "Helvetica Now Display",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  margin="normal"
                  type="email"
                  sx={{
                    fontFamily: "Helvetica Now Display",
                    "& .MuiInputBase-input": {
                      fontFamily: "Helvetica Now Display",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.8rem",
                      fontFamily: "Helvetica Now Display",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  margin="normal"
                  type="tel"
                  sx={{
                    fontFamily: "Helvetica Now Display",
                    "& .MuiInputBase-input": {
                      fontFamily: "Helvetica Now Display",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.8rem",
                      fontFamily: "Helvetica Now Display",
                    },
                  }}
                />
                <Button
                  sx={{
                    backgroundColor: "rgb(0, 13, 16)",
                    mt: "1.5rem",
                    fontFamily: "Helvetica Now Display",
                  }}
                  className="customBtn"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactUs;

import React, { useEffect } from "react";
import styles from "./wellBeing.module.css";
import { scrollToTop } from "../../utilities/scrollToTop";
import { useLocation } from "react-router-dom";
const WellBeing = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [location]);
  return (
    <div className={`${styles.activityCont} `}>
      <h1 className={`${styles.header} `}>Product Modules</h1>
      <section className={styles.topVideoCon}>
        <video
          autoPlay
          loop
          muted
          preload="metadata"
          src="/assets/content_image/12169637-hd_1920_1080_30fps.mp4"
        ></video>
      </section>
      <section className={`${styles.activitySec_1}`}>
        <div className={styles.activity_1}>
          <div className={styles.productsModulesImg}>
            <img
              src="/assets/content_image/Activity_0.jpeg"
              alt="Product landing"
            />
          </div>
          <div className={styles.info}>
            <h2 className={styles.header}>EngageWell</h2>
            <p className={styles.paragraph}>
              Group Activities for boosting employees’s engagement.
            </p>
            <div className={styles.retreats}>
              <h3>Retreats</h3>
              <ul className={styles.activity_list}>
                <li>Yoga</li>
                <li>Meditation</li>
              </ul>
            </div>
            <div className={styles.retreats}>
              <h3>Sports</h3>
              <ul className={styles.activity_list}>
                <li>Paintball</li>
                <li>Paddleboard Race</li>
                <li>Kanoe</li>
                <li>Treasure Hunts</li>
                <li>Laser Tag</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.activity_2}>
          <div className={styles.productsModulesImg}>
            <img
              src="/assets/content_image/Art therapy.webp"
              alt="Product landing"
            />
          </div>
          <div className={styles.info}>
            <h2 className={styles.header}>OneWell</h2>
            <p className={styles.paragraph}>
              Individual* well being activities.
            </p>
            <div className={styles.retreats}>
              <ul className={styles.activity_list}>
                <li>Art therapy</li>
                <li>
                  Painting/sketching Sessions at work{" "}
                  <span style={{ fontWeight: "700" }}>
                    (taught and guided by activity providers)
                  </span>
                  .
                </li>
              </ul>
            </div>
            <p
              style={{
                fontSize: "1rem",
                marginBottom: " 0.3rem",
                fontWeight: "400",
                color: "rgba(0, 13, 16, 0.761)",
                marginTop: "0.5rem",
              }}
            >
              * Can be made available for small setup group
            </p>
          </div>
        </div>
        <div className={styles.activity_3}>
          <div className={styles.productsModulesImg}>
            <img
              src="/assets/content_image/BeingConnect 1.jpeg"
              alt="Product landing"
            />
          </div>
          <div className={styles.info}>
            <h2 className={styles.header}>Being connect</h2>
            <div className={styles.retreats}>
              <ul className={styles.activity_list}>
                <li>
                  Training sessions for{" "}
                  <span style={{ fontWeight: "700", color: "rgb(0, 13, 16)" }}>
                    managers
                  </span>{" "}
                  focused on developing empathetic skills to enhance their
                  understanding of employee well-being, happiness, and
                  engagement.
                </li>
                <li>
                  Activities only for leadership - catering to their self
                  well-being.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WellBeing;


import React, { useState } from 'react';
import styles from "./login.module.css";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const Login = ({ setAlert }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordErrors, setPasswordErrors] = useState({
    minLength: true,
    hasUpperCase: true,
    hasNumber: true,
    hasSpecialChar: true,
  });
  const [isValidForm, setIsValidForm] = useState(false);
  const navigate = useNavigate();

  // Password validation function
  const validatePassword = (password) => {
    const errors = {
      minLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordErrors(errors);
    // Update form validity based on password validation
    setIsValidForm(Object.values(errors).every((value) => value === true));
    return Object.values(errors).every((value) => value === true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.email && formData.password) {
      // Validate password before submitting
      const isPasswordValid = validatePassword(formData.password);
      if (!isPasswordValid) {
        // setErrorMessage('Password does not meet the required criteria');
        return; // Prevent submission if password is invalid
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
          email: formData.email,
          password: formData.password,
        });

        setFormData({ email: "", password: "" });
        setAlert({
          open: true,
          message: "Logged in successfully",
          severity: "success",
        });
        Cookies.set('token', response.data.token, { expires: 7, secure: true, sameSite: 'Strict' });
        setTimeout(() => {
          navigate('/');
        }, 1000);
      } catch (error) {
        console.error('Login Error:', error);
        setAlert({
          open: true,
          message: error.response ? error.response.data.message : error.message,
          severity: "error",
        });
        setErrorMessage('Invalid email or password');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className={`${styles.loginContainer}  `}>
      <h2 className={styles.header}>Sign in</h2>
      <div className={styles.loginFormContainer}>
        <div className={styles.businessesCont}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
              sx={{
                fontFamily: "Helvetica Now Display",
                '& .MuiInputBase-input': { fontFamily: "Helvetica Now Display" },
                '& .MuiInputLabel-root': { fontSize: '0.8rem', fontFamily: "Helvetica Now Display" },
              }}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              margin="normal"
              required
              sx={{
                fontFamily: "Helvetica Now Display",
                '& .MuiInputBase-input': { fontFamily: "Helvetica Now Display" },
                '& .MuiInputLabel-root': { fontSize: '0.8rem', fontFamily: "Helvetica Now Display" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff sx={{ fontSize: "2rem" }} /> : <Visibility sx={{ fontSize: "2rem" }} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!passwordErrors.minLength || !passwordErrors.hasUpperCase || !passwordErrors.hasNumber || !passwordErrors.hasSpecialChar}
              helperText={
                !passwordErrors.minLength ? "• At least 8 characters" :
                !passwordErrors.hasUpperCase ? "• Includes a capital letter" :
                !passwordErrors.hasNumber ? "• Includes a number" :
                !passwordErrors.hasSpecialChar ? "• Includes a special character" :
                ""
              }
            />

            <Button
              sx={{
                backgroundColor: "rgb(0, 13, 16)",
                mt: "1.5rem",
                fontFamily: "Helvetica Now Display"
              }}
              className="customBtn"
              type="submit"
              variant="contained"
            >
              Sign In
            </Button>
          </form>
        </div>
      </div>
      <div onClick={() => navigate("/register")} className={styles.confirm}>
        <span className={styles.info}>Don't have an account yet? </span>
        <span className={styles.btn}>Register</span>
      </div>
    </div>
  );
};

export default Login;

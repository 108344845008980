import React from "react";
import styles from "./navbar.module.css";
import { Button, Drawer, Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigation = useNavigate();
  const [DraweroOpen, setDraweroOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const section1_sub1_content = !isMobile
    ? [
        {
          header: "EngageWell",
          route: "/products",
        },
        {
          header: "OneWell",
          route: "/products",
        },
        {
          header: "Wellbeing Connect",
          route: "/products",
        },
      ]
    : [
        {
          header: "Businesses On-boarding",
          route: "/onBoarding",
        },
        {
          header: "Offer Well-being",
          route: "/activities",
        },
      ];
  const toggleDrawer = (newOpen) => () => {
    setDraweroOpen(newOpen);
  };
  const navgigateToNext = (path) => {
    navigation(path);
  };
  let paths = ["/onBoarding", "/activities", "/products", "/aboutus"];
  return (
    <div
      className={`${styles.navContainer}`}
      id="navbar"
      style={{
        minHeight:
          location.pathname === "/"
            ? window.innerHeight < 601
              ? "45vh"
              :!isMobile? "60vh":"45vh"
            : paths.includes(location.pathname)
            ? isMobile
              ? "31vh"
              : "41vh"
            : isMobile
            ? "10vh"
            : "20vh",
      }}
    >
      <Drawer
        anchor="right"
        open={DraweroOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            minWidth: isMobile ? "70vw" : "40vw",
            maxWidth: "70vw",
          },
        }}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.drawerHeader}>
            <div className={styles.navBtnContainer}>
              {/* <Button
                sx={{ marginRight: "1.3rem", backgroundColor: "rgb(0,13,16)",whiteSpace:"nowrap" }}
                onClick={() => {
                  navgigateToNext( "/onBoarding");
                  setDraweroOpen(false);
                }}
                variant="contained"
                className="customBtn  onlyMobile"
              >
                Businesses On-boarding
              </Button>
              <Button
                sx={{ marginRight: "1.3rem", backgroundColor: "rgb(0,13,16)",whiteSpace:"nowrap" }}
                onClick={() => {
                  navgigateToNext("/activities");
                  setDraweroOpen(false);
                }}
                variant="contained"
                className="customBtn  onlyMobile"
              >
               Offer Well-being
              </Button> */}
              <Button
                sx={{
                  marginRight: "1.3rem",
                  backgroundColor: "rgb(0,13,16)",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  navgigateToNext("/products");
                  setDraweroOpen(false);
                }}
                variant="contained"
                className="customBtn "
              >
                Product modules
              </Button>
              {/* <Button sx={{marginRight:"4rem",backgroundColor: "black"}} onClick={()=>{navgigateToNext("/register")}} variant="contained" className="customBtn ">Register</Button> */}
            </div>
            <Button
              sx={{
                padding: isMobile ? "1.9rem 2rem" : "17px 19px",
                borderRadius: "50%",
                minWidth: "fit-content",
                // background: "rgb(0,13,16)",
                border: "1px solid rgb(0,13,16)",
              }}
              onClick={toggleDrawer(false)}
              className="hoverActive "
            >
              <ClearIcon
                sx={{ color: "rgb(0,13,16)" }}
                fontSize="small"
              ></ClearIcon>
            </Button>
          </div>
          <div className={styles.drawerBody}>
            {section1_sub1_content.map((data) => {
              return (
                <div
                  onClick={() => {
                    navgigateToNext(data.route);
                    setDraweroOpen(false);
                  }}
                  key={data.header}
                  className={`${styles.section1_sub1_item}  `}
                >
                  <h3>{data.header}</h3>
                  {/* <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.info,
                      }}
                    />
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
      <div className={`${styles.navcont}  `}>
        <div className={`${styles.logoCont}  `}>
          <img
            onClick={() => {
              navgigateToNext("/");
            }}
            src="/assets/content_image/Logo_big-removebg-preview.png"
            alt="logo"
            className={styles.logo}
          />
        </div>
        {/* <h2 className={styles.title}>Your employees well-being start here.</h2> */}
        <div className={styles.tabsCont}>
          <h5
            onClick={() => {
              navgigateToNext("/onBoarding");
            }}
            className={`${styles.tab} ${
              location.pathname === "/onBoarding" ? styles.active : ""
            }`}
          >
            Businesses On-boarding
          </h5>
          <h5
            onClick={() => {
              navgigateToNext("/activities");
            }}
            className={`${styles.tab} ${
              location.pathname === "/activities" ? styles.active : ""
            }`}
          >
            Offer Well-being
          </h5>
          {
            <h5
              onClick={() => {
                navgigateToNext("/products");
              }}
              className={`${styles.tab} ${
                location.pathname === "/products" ? styles.active : ""
              }`}
            >
              Product
            </h5>
          }

          <Button
            sx={{
              // padding: "15px 16px",
              borderRadius: "30px",
              minWidth: "fit-content",
              background: "black",
              paddingLeft: "15px",
            }}
          >
            <MenuIcon
              onClick={toggleDrawer(true)}
              sx={{ color: "white", marginRight: "0.8rem" }}
              fontSize="small"
            ></MenuIcon>
            <AccountCircleIcon
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ color: "white" }}
              fontSize="large"
            ></AccountCircleIcon>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            sx={{
              marginTop: "13px",
              padding: "2rem",
              "& .MuiMenu-paper": {
                padding: "0.5rem", // Add padding to the menu
                minWidth: "10rem", // Optional: Make the menu width consistent
                "@media (max-width: 600px)": {
                  minWidth: "15rem",
                },
              },
              "& .MuiMenuItem-root": {
                fontSize: "0.8rem", // Decrease font size of the list items
                padding: "0.4rem 0.8rem",
                fontFamily: "'Helvetica Now Display'", // Add padding to list items for better spacing
                color: "rgb(0, 13, 16)",
                fontWeight: "400",
                "@media (max-width: 600px)": {
                  minHeight: "2rem",
                  fontSize: "1.1rem",
                },
              },
              "& .MuiMenu-list": {
                padding: "0px",
              },
            }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                navgigateToNext("/register");
                handleClose();
              }}
            >
              Register
            </MenuItem>
            <MenuItem
              onClick={() => {
                navgigateToNext("/login");
                handleClose();
              }}
            >
              Login
            </MenuItem>
            <MenuItem
              onClick={() => {
                navgigateToNext("/contactus");
                handleClose();
              }}
            >
              Contact
            </MenuItem>
            <MenuItem
              onClick={() => {
                navgigateToNext("/aboutus");
                handleClose();
              }}
            >
              About
            </MenuItem>
          </Menu>
        </div>
      </div>
      {location.pathname === "/" && (
        <div className={styles.webTitle}>
          <h2 className={styles.title}>Your Employees' Well-being Starts Here</h2>
        </div>
      )}
    </div>
  );
};

export default Navbar;

import React, { useEffect } from "react";
import styles from "./activities.module.css";
import { scrollToTop } from "../../utilities/scrollToTop";
import { useLocation } from "react-router-dom";

const Activities = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [location]);

  return (
    <div>
      <section className={`${styles.topVideoCon} `}>
        <video
          autoPlay
          loop
          muted
          preload="metadata"
          src="/assets/content_image/10493910-uhd_4096_2160_25fps.mp4"
        ></video>
      </section>
      <div className={`${styles.activityContent} `}>
        <div className={`${styles.activityImg} `}>
          <img src="/assets/content_image/Activity_1.jpeg" alt="Activity" />
        </div>
        <div className={`${styles.activityText} `}>
          <h2 className={styles.heading}>Expand the Well-being Impact</h2>
          <p
            className={styles.paragraph}
            style={{ marginBottom: "0.5rem", lineHeight: "1.5rem" }}
          >
            Do you provide <strong>well-being services</strong>?
          </p>
          <p
            className={styles.paragraph}
            style={{ marginBottom: "0.5rem", lineHeight: "1.5rem" }}
          >
            OuiMoose will help you expand your impact. We will be a part of your
            mission.
          </p>
          <p
            className={styles.paragraph}
            style={{ marginBottom: "0.5rem", lineHeight: "1.5rem" }}
          >
            Our platform connects you with businesses which care about their
            employee’s well-being and happiness.{" "}
          </p>
          <h3 className={styles.subHeading}>Why Join OuiMoose?</h3>
          <ul className={styles.activity_list}>
            <li>Connect with companies committed to employee well-being.</li>
            <li>
              Reach engaged audiences seeking workplace wellness solutions.
            </li>
            <li>
              Transform the mindset of companies to care about employee
              well-being.
            </li>
          </ul>

          <h3 className={styles.ctaHeading}>
            Partner with OuiMoose for Thriving Workplaces
          </h3>
          <p className={styles.paragraph}>
            Join us and make a difference in everyone’s well-being by creating
            healthier, happier workplaces that prioritize well-being. Let’s
            collaborate to make a meaningful difference in everyone’s lives!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Activities;

import React, { useEffect } from "react";
import styles from "./aboutUs.module.css";
import { scrollToTop } from "../../utilities/scrollToTop";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [location]);

  return (
    <div>
      <section className={`${styles.topVideoCon} `}>
        <video
          autoPlay
          loop
          muted
          src="/assets/content_image/5119363-uhd_2560_1440_30fps.mp4"
        ></video>
      </section>
      <div className={`${styles.onBoardContent} `}>
        
        <div className={`${styles.onBoardText} `}>
          <p className={styles.paragraph}  style={{fontSize:"2.7rem",fontWeight
            :"400",lineHeight:"3rem",color:"rgb(0, 13, 16)"}}>
            OuiMoose offers businesses a platform to care about the well-being
            of their employees by managing end-to-end employee happiness and
            engagement, improving mental and physical well-being
          </p>

          <h3 className={styles.subHeading}>Our Mission</h3>
          <p className={styles.paragraph}>
            OuiMoose is on a mission to help every single company take care of
            their employees’ well-being within and beyond work hours.
          </p>

          <h3 className={styles.ctaHeading}>
            Join Us in Transforming Workplaces
          </h3>
          <p className={styles.paragraph}>
            Together, let’s create healthier, happier workplaces for everyone!
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;


import React, { useEffect } from "react";
import styles from "./onboarding.module.css";
import { scrollToTop } from "../../utilities/scrollToTop";
import { useLocation } from "react-router-dom";

const OnboardingPage = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [location]);

  return (
    <div>
      <section className={`${styles.topVideoCon}`}>
        <video
          autoPlay
          loop
          muted
          src="/assets/content_image/5119363-uhd_2560_1440_30fps.mp4"
          preload="metadata"
        ></video>
      </section>
      <div className={`${styles.onBoardContent}`}>
        <div className={`${styles.onBoardImg} `}>
          <img src="/assets/content_image/Landing 2.jpeg" alt="Activity" />
        </div>
        <div className={`${styles.onBoardText}`}>
          <h2 className={`${styles.heading}`}>Transform Employee Well-being</h2>
          <p className={styles.paragraph}>
            Businesses often find it challenging to keep employees{" "}
            <strong>engaged and satisfied</strong>, sometimes overlooking their
            overall <strong>well-being</strong>.
          </p>
          <p className={styles.paragraph}>
            With <strong>OuiMoose</strong>, you can transform this dynamic.
          </p>
          <p className={styles.paragraph}>
            Partner with us to create a vibrant workplace culture that
            prioritizes employee happiness and drives productivity.
          </p>
          <p className={styles.paragraph}>
            Let’s elevate your team’s experience together!
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;



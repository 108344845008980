import React, { useEffect, useState } from "react";
import styles from "./authPage.module.css";
import { useLocation} from "react-router-dom";
import Register from "../../components/Register/Register";
import Login from "../../components/Login/Login";
import { scrollToTop } from "../../utilities/scrollToTop";
import { Alert, Snackbar } from "@mui/material";
const AuthPage = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [location]);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  return (
    <div className={`${styles.authContainer}  `}>
      {location.pathname === "/register" ? (
        <Register setAlert={setAlert} />
      ) : (
        <Login setAlert={setAlert}></Login>
      )}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AuthPage;

import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import LandingPage from "./pages/landingPage/LandingPage";
import OnboardingPage from "./pages/onBoarding/OnboardingPage";
import Activities from "./pages/activities/Activities";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import AuthPage from "./pages/authenticationPage/AuthPage";
import WellBeing from "./pages/wellBeingActivities/WellBeing";
import ContactUs from "./pages/contactUs/ContactUs";
import AboutUs from "./pages/aboutUs/AboutUs";

function App() {
  const backgroundStyle = {
    background:
      "linear-gradient(to bottom, #b3c7d6, #e6f1f5, #f0f8ff, #c9e0f7)",
  };

  return (
    <div className="App" style={backgroundStyle}>
      <Navbar />
      <main
        style={{
          minHeight:"57vh"
        }}
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/onBoarding" element={<OnboardingPage />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="/register" element={<AuthPage />} />
          <Route path="/products" element={<WellBeing />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

import React from "react";
import styles from "./contentSection.module.css";
const ContentSection = ({ content, customStyles, page }) => {
  return (
    <div className={`${styles.contentSectionCont}`}>
      <h2 style={customStyles}>{content}</h2>
      {page === "landingPage" && (
        <h2 style={{ ...customStyles, fontSize: "2rem", fontWeight: "400" }}>
          No more with OuiMoose as your partner
        </h2>
      )}
    </div>
  );
};

export default ContentSection;
